var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "modal-width": _vm.computedModalWidth,
      "color": "var(--v-assembly_templates-base)",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('AppPartReferenceDisplay', {
          attrs: {
            "part-reference-id": _vm.id
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Close "), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };
/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalPartReferenceInfo.vue?vue&type=template&id=eb133986"
import script from "./ModalPartReferenceInfo.ts?vue&type=script&lang=ts&external"
export * from "./ModalPartReferenceInfo.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
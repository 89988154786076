import { buildWebStorage, setupCache } from 'axios-cache-interceptor';
import { createAxiosInstance } from '../vue_plugins/axios';
// minimum Time To Live (this is overridable for each request)
const TTL = 1000 * 60; // 60s
const createDalAxiosInstance = () => {
    const axiosInstanceWithCaching = setupCache(createAxiosInstance(), {
        storage: buildWebStorage(localStorage, ''),
        ttl: TTL
    });
    return axiosInstanceWithCaching;
};
const findMatchingKeysToSpoil = (prefix) => {
    const matchingKeys = [];
    // Loop through all keys in localStorage
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        // Check if the key starts with the specified prefix
        if (key && key.startsWith(prefix)) {
            matchingKeys.push(key);
        }
    }
    return matchingKeys.length
        ? matchingKeys.reduce((obj, key) => {
            obj[key] = 'delete';
            return obj;
        }, {})
        : null;
};
// #region Assembly templates
const ASSEMBLY_TEMPLATE_COLLECTION_CACHE_KEY = 'collection_assembly_templates';
const ASSEMBLY_TEMPLATE_URL_SLUG = `assembly-templates`;
export const clearAssemblyTemplateCollectionCache = () => {
    const keys = findMatchingKeysToSpoil(ASSEMBLY_TEMPLATE_COLLECTION_CACHE_KEY);
    if (!keys) {
        return;
    }
    Object.keys(keys).forEach((key) => {
        localStorage.removeItem(key);
    });
};
export const clearAssemblyTemplateCacheById = (id) => {
    if (!id) {
        return;
    }
    localStorage.removeItem(id);
};
export const deleteAssemblyTemplate = (id) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(ASSEMBLY_TEMPLATE_COLLECTION_CACHE_KEY);
    return http.delete(`/${ASSEMBLY_TEMPLATE_URL_SLUG}/${id}`, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const getAssemblyTemplates = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${ASSEMBLY_TEMPLATE_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${ASSEMBLY_TEMPLATE_URL_SLUG}`, {
        id,
        params
    });
};
export const getAssemblyTemplate = (id) => {
    const http = createDalAxiosInstance();
    return http.get(`/${ASSEMBLY_TEMPLATE_URL_SLUG}/${id}`, {
        id
    });
};
export const postAssemblyTemplate = (body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(ASSEMBLY_TEMPLATE_COLLECTION_CACHE_KEY);
    const update = {
        ...spoilers
    };
    if (body.parentId) {
        update[body.parentId] = 'delete';
    }
    return http.post(`/${ASSEMBLY_TEMPLATE_URL_SLUG}`, {
        ...body
    }, {
        cache: {
            update
        }
    });
};
export const putAssemblyTemplate = (id, body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(ASSEMBLY_TEMPLATE_COLLECTION_CACHE_KEY);
    return http.put(`/${ASSEMBLY_TEMPLATE_URL_SLUG}/${id}`, {
        ...body
    }, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
// #endregion
// #region Auto-hardware lists
const AUTO_HARDWARE_LIST_COLLECTION_CACHE_KEY = 'collection_auto_hardware_lists';
const AUTO_HARDWARE_LIST_URL_SLUG = `auto-hardware-lists`;
export const deleteAutoHardwareList = (id) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(AUTO_HARDWARE_LIST_COLLECTION_CACHE_KEY);
    return http.delete(`/${AUTO_HARDWARE_LIST_URL_SLUG}/${id}`, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const getAutoHardwareList = (id) => {
    const http = createDalAxiosInstance();
    return http.get(`/${AUTO_HARDWARE_LIST_URL_SLUG}/${id}`, {
        id
    });
};
export const getAutoHardwareLists = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${AUTO_HARDWARE_LIST_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${AUTO_HARDWARE_LIST_URL_SLUG}`, {
        id,
        params
    });
};
export const postAutoHardwareList = (body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(AUTO_HARDWARE_LIST_COLLECTION_CACHE_KEY);
    return http.post(`/${AUTO_HARDWARE_LIST_URL_SLUG}`, {
        ...body
    }, {
        cache: {
            update: {
                ...spoilers
            }
        }
    });
};
export const putAutoHardwareList = (id, body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(AUTO_HARDWARE_LIST_COLLECTION_CACHE_KEY);
    return http.put(`/${AUTO_HARDWARE_LIST_URL_SLUG}/${id}`, {
        ...body
    }, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
// #endregion
// #region Contacts
const CONTACTS_COLLECTION_CACHE_KEY = 'collection_contacts';
const CONTACTS_URL_SLUG = `contacts`;
export const getContacts = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${CONTACTS_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${CONTACTS_URL_SLUG}`, {
        id,
        params
    });
};
// #endregion
// #region Delivery orders
const DELIVERY_ORDER_COLLECTION_CACHE_KEY = 'collection_delivery_orders';
const DELIVERY_ORDER_URL_SLUG = `documents/delivery-orders`;
export const clearDeliveryOrderCollectionCache = () => {
    const keys = findMatchingKeysToSpoil(DELIVERY_ORDER_COLLECTION_CACHE_KEY);
    if (!keys) {
        return;
    }
    Object.keys(keys).forEach((key) => {
        localStorage.removeItem(key);
    });
};
export const clearDeliveryOrderCacheById = (id) => {
    if (!id) {
        return;
    }
    localStorage.removeItem(id);
};
export const deleteDeliveryOrder = (deliveryOrderId) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(DELIVERY_ORDER_COLLECTION_CACHE_KEY);
    return http.delete(`/documents/${deliveryOrderId}`, {
        cache: {
            update: {
                [`${deliveryOrderId}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const deleteDeliveryOrderLineItem = (deliveryOrderId, params) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(DELIVERY_ORDER_COLLECTION_CACHE_KEY);
    return http.delete(`/${DELIVERY_ORDER_URL_SLUG}/${deliveryOrderId}/line-items`, {
        params,
        cache: {
            update: {
                [`${deliveryOrderId}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const getDeliveryOrder = (id) => {
    const http = createDalAxiosInstance();
    return http.get(`/${DELIVERY_ORDER_URL_SLUG}/${id}`, {
        id
    });
};
export const getDeliveryOrders = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${DELIVERY_ORDER_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${DELIVERY_ORDER_URL_SLUG}`, {
        id,
        params
    });
};
export const getDeliveryOrderLineItems = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${DELIVERY_ORDER_COLLECTION_CACHE_KEY}_line_items_${customKeyWithParams}`;
    return http.get(`/${DELIVERY_ORDER_URL_SLUG}/line-items`, {
        id,
        params
    });
};
export const postDeliveryOrderLineItem = (deliveryOrderId, body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(DELIVERY_ORDER_COLLECTION_CACHE_KEY);
    return http.post(`/${DELIVERY_ORDER_URL_SLUG}/${deliveryOrderId}/line-items`, {
        ...body
    }, {
        cache: {
            update: {
                [`${deliveryOrderId}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const putDeliveryOrderLineItem = (deliveryOrderId, // document id
lineItemId, // change_set id
body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(DELIVERY_ORDER_COLLECTION_CACHE_KEY);
    return http.put(`/${DELIVERY_ORDER_URL_SLUG}/${deliveryOrderId}/line-items/${lineItemId}`, {
        ...body
    }, {
        cache: {
            update: {
                [`${deliveryOrderId}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const closeDeliveryOrderLineItems = (deliveryOrderId, // document id
body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(DELIVERY_ORDER_COLLECTION_CACHE_KEY);
    return http.put(`/${DELIVERY_ORDER_URL_SLUG}/${deliveryOrderId}/line-items`, {
        ...body
    }, {
        cache: {
            update: {
                [`${deliveryOrderId}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const putDeliveryOrder = (id, body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(DELIVERY_ORDER_COLLECTION_CACHE_KEY);
    return http.put(`/${DELIVERY_ORDER_URL_SLUG}/${id}`, {
        ...body
    }, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
// #endregion
// #region Hardware search
const HARDWARE_SEARCH_COLLECTION_CACHE_KEY = 'collection_hardware_search';
const HARDWARE_SEARCH_URL_SLUG = `hardware`;
export const getAllHardware = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${HARDWARE_SEARCH_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${HARDWARE_SEARCH_URL_SLUG}`, {
        id,
        params
    });
};
// #endregion
// #region Item instances
const ITEM_INSTANCE_COLLECTION_CACHE_KEY = 'collection_item_instances';
const ITEM_INSTANCE_URL_SLUG = `hardware/item-instances`;
export const getItemInstance = (id) => {
    const http = createDalAxiosInstance();
    return http.get(`/${ITEM_INSTANCE_URL_SLUG}/${id}`, {
        id
    });
};
export const getItemInstances = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${ITEM_INSTANCE_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${ITEM_INSTANCE_URL_SLUG}`, {
        id,
        params
    });
};
// #endregion
// #region Locations
const LOCATION_COLLECTION_CACHE_KEY = 'collection_locations';
const LOCATION_URL_SLUG = `locations`;
export const deleteLocation = (id) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(LOCATION_COLLECTION_CACHE_KEY);
    return http.delete(`/${LOCATION_URL_SLUG}/${id}`, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const getLocation = (id) => {
    const http = createDalAxiosInstance();
    return http.get(`/${LOCATION_URL_SLUG}/${id}`, {
        id
    });
};
export const getLocations = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${LOCATION_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${LOCATION_URL_SLUG}`, {
        id,
        params
    });
};
export const putLocation = (id, body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(LOCATION_COLLECTION_CACHE_KEY);
    return http.put(`/${LOCATION_URL_SLUG}/${id}`, {
        ...body
    }, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
// #endregion
// #region Logistics
const LOGISTICS_COLLECTION_CACHE_KEY = 'collection_logistics';
const LOGISTICS_URL_SLUG = `logistics`;
export const clearLogisticsCollectionCache = () => {
    const keys = findMatchingKeysToSpoil(LOGISTICS_COLLECTION_CACHE_KEY);
    if (!keys) {
        return;
    }
    Object.keys(keys).forEach((key) => {
        localStorage.removeItem(key);
    });
};
export const getLogisticsAllocationMatrix = () => {
    const http = createDalAxiosInstance();
    return http.get(`/${LOGISTICS_URL_SLUG}/allocation-matrix`, {
        id: LOGISTICS_COLLECTION_CACHE_KEY
    });
};
// #endregion
// #region Outages
const OUTAGE_COLLECTION_CACHE_KEY = 'collection_outages';
const OUTAGE_URL_SLUG = `outages`;
export const deleteOutage = (id) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(OUTAGE_COLLECTION_CACHE_KEY);
    return http.delete(`/${OUTAGE_URL_SLUG}/${id}`, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const getOutages = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${OUTAGE_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${OUTAGE_URL_SLUG}`, {
        id,
        params
    });
};
export const postOutage = (body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(OUTAGE_COLLECTION_CACHE_KEY);
    return http.post(`/${OUTAGE_URL_SLUG}`, {
        ...body
    }, {
        cache: {
            update: {
                ...spoilers
            }
        }
    });
};
export const putOutage = (id, body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(OUTAGE_COLLECTION_CACHE_KEY);
    return http.put(`/${OUTAGE_URL_SLUG}/${id}`, {
        ...body
    }, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
// #endregion
// #region Part references
const PART_REFERENCES_COLLECTION_CACHE_KEY = 'collection_part_references';
const PART_REFERENCES_URL_SLUG = `part-references`;
export const deletePartReference = (id) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(PART_REFERENCES_COLLECTION_CACHE_KEY);
    return http.delete(`/${PART_REFERENCES_URL_SLUG}/${id}`, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
export const getPartReference = (id) => {
    const http = createDalAxiosInstance();
    return http.get(`/${PART_REFERENCES_URL_SLUG}/${id}`, {
        id
    });
};
export const getPartReferences = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${PART_REFERENCES_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${PART_REFERENCES_URL_SLUG}`, {
        id,
        params
    });
};
export const postPartReference = (body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(PART_REFERENCES_COLLECTION_CACHE_KEY);
    return http.post(`/${PART_REFERENCES_URL_SLUG}`, {
        ...body
    }, {
        cache: {
            update: {
                ...spoilers
            }
        }
    });
};
export const putPartReference = (id, body) => {
    const http = createDalAxiosInstance();
    const spoilers = findMatchingKeysToSpoil(PART_REFERENCES_COLLECTION_CACHE_KEY);
    return http.put(`/${PART_REFERENCES_URL_SLUG}/${id}`, {
        ...body
    }, {
        cache: {
            update: {
                [`${id}`]: 'delete',
                ...spoilers
            }
        }
    });
};
// #endregion
// #region Tracking documents
const TRACKING_DOCS_COLLECTION_CACHE_KEY = 'collection_tracking_documents';
const TRACKING_DOCS_URL_SLUG = `documents`;
export const getTrackingDocuments = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${TRACKING_DOCS_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${TRACKING_DOCS_URL_SLUG}`, {
        id,
        params
    });
};
// #endregion
// #region Users
const USERS_COLLECTION_CACHE_KEY = 'collection_users';
const USERS_URL_SLUG = `users`;
export const getUsers = (params) => {
    const http = createDalAxiosInstance();
    const customKeyWithParams = params ? btoa(JSON.stringify(params)) : '';
    const id = `${USERS_COLLECTION_CACHE_KEY}_${customKeyWithParams}`;
    return http.get(`/${USERS_URL_SLUG}`, {
        id,
        params
    });
};
// #endregion

import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Side } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { DB_DEFAULT } from '~/nasa_ui/constants';
import { SideDisplay } from '~/nasa_ui/types';
let PartSingleLineDisplay = class PartSingleLineDisplay extends Mixins(BaseVue) {
    DB_DEFAULT = DB_DEFAULT;
    Side = Side;
    SideDisplay = SideDisplay;
    drawingNumber;
    asBuiltNumber;
    side;
    size;
    lotNumber;
    serialNumber;
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], PartSingleLineDisplay.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], PartSingleLineDisplay.prototype, "asBuiltNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], PartSingleLineDisplay.prototype, "side", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], PartSingleLineDisplay.prototype, "size", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], PartSingleLineDisplay.prototype, "lotNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], PartSingleLineDisplay.prototype, "serialNumber", void 0);
PartSingleLineDisplay = __decorate([
    Component
], PartSingleLineDisplay);
export default PartSingleLineDisplay;

var _a;
import { __decorate, __metadata } from "tslib";
import { isEmpty } from 'lodash';
import VueJsonPretty from 'vue-json-pretty';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { Maybe } from '~/nasa_ui/types';
import { getPartReference } from '~/nasa_ui/utils';
import { transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppPartReferenceDisplay = class AppPartReferenceDisplay extends Mixins(BaseVue) {
    DEFAULT_DASH = DEFAULT_DASH;
    partReference = null;
    viewingRawData = false;
    isEmpty = isEmpty;
    partReferenceId;
    get transformedPartReference() {
        if (!this.partReference) {
            return null;
        }
        return transformPartReferenceResponses([this.partReference])[0];
    }
    async onPartReferenceIdChange() {
        if (!this.partReferenceId) {
            return;
        }
        try {
            const resp = await getPartReference(this.partReferenceId);
            this.partReference = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppPartReferenceDisplay.prototype, "partReferenceId", void 0);
__decorate([
    Watch('partReferenceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppPartReferenceDisplay.prototype, "onPartReferenceIdChange", null);
AppPartReferenceDisplay = __decorate([
    Component({
        components: {
            VueJsonPretty
        }
    })
], AppPartReferenceDisplay);
export default AppPartReferenceDisplay;

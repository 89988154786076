import axios from 'axios';
import qs from 'qs';
import { CosmicEnvironment, EntityType, getEnvironment } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils';
import { createDbDateFromString } from '~/nasa_ui/utils/dates';
import { AUTH_PATH } from '../constants';
import { getActiveSupportContext } from '../mixins/CurrentUserMixin';
// Date transforms
const isDateString = (property) => {
    return property.endsWith('Date');
};
const isDateTimeString = (property) => {
    return property.endsWith('DateTime');
};
const addTimeZoneToDateProperties = (obj) => {
    if (!isNullOrUndefined(obj)) {
        Object.entries(obj)
            .filter(([key, val]) => key !== 'attributes')
            .forEach(([key, val]) => {
            if (typeof val === 'object') {
                addTimeZoneToDateProperties(val);
            }
            else {
                if (isDateString(key) && val) {
                    if (val.toString().endsWith('T00:00:00Z') || val.toString().endsWith('T00:00:00+00:00')) {
                        val = val.toString().replace('T00:00:00Z', 'T12:00:00Z').replace('T00:00:00+00:00', 'T12:00:00+00:00');
                    }
                    const utcDate = createDbDateFromString(val);
                    obj[key] = utcDate.substring(0, 10);
                }
                else if (isDateTimeString(key) && val) {
                    obj[key] = createDbDateFromString(val);
                }
            }
        });
    }
};
const transformDates = (payload, headers) => {
    if (headers['Content-Type']?.includes('multipart/form-data')) {
        return payload;
    }
    if (!payload) {
        return;
    }
    addTimeZoneToDateProperties(payload);
    return JSON.stringify(payload);
};
// if obj contains properties 'drawingNumber' 'asBuiltNumber' 'side' 'size' 'serialNumber' 'lotNumber' 'id' 'location'  || 'locationId' then its an item_instance
const hasInventoryFields = (obj) => {
    const requiredFields = ['drawingNumber', 'asBuiltNumber', 'side', 'size', 'id'];
    const itemInstanceOptionalFields = ['serialNumber', 'lotNumber'];
    const inventoryOptionalFields = ['location', 'locationId'];
    return (requiredFields.every((field) => obj.hasOwnProperty(field) && !isNullOrUndefined(obj[field])) &&
        itemInstanceOptionalFields.some((field) => obj.hasOwnProperty(field)) &&
        inventoryOptionalFields.some((field) => obj.hasOwnProperty(field)));
};
// if obj contains properties 'drawingNumber' 'asBuiltNumber' 'side' 'size' 'serialNumber' 'lotNumber' 'id'  then its an item_instance
const hasItemInstanceFields = (obj) => {
    const requiredFields = ['drawingNumber', 'asBuiltNumber', 'side', 'size', 'id'];
    const optionalFields = ['serialNumber', 'lotNumber'];
    return (requiredFields.every((field) => obj.hasOwnProperty(field) && !isNullOrUndefined(obj[field])) &&
        optionalFields.some((field) => obj.hasOwnProperty(field)));
};
// if obj contains properties 'drawingumber' 'asBuiltNumber' and 'side' then its an item_master
const hasItemMasterFields = (obj) => {
    const fields = ['drawingNumber', 'asBuiltNumber', 'side'];
    return fields.every((field) => obj.hasOwnProperty(field) && !isNullOrUndefined(obj[field]));
};
// if obj contains just the property 'drawingNumber'  then its an item_drawing
const hasItemDrawingFields = (obj) => {
    const fields = ['drawingNumber'];
    return fields.every((field) => obj.hasOwnProperty(field) && !isNullOrUndefined(obj[field]));
};
/**
 * The goal of this function is to determine if the object is a hardware object based on its
 * properties. If it determines that the object is a hardware object, a new property is added
 *
 * `nodeId`
 *
 * This should be exactly what postgraphile gave us.
 *
 * NOTE: If the shape of the response isnt one of the listed format you will have to add that condition.
 *
 * @param obj IAssumedHardwareInput
 * @returns obj IAssumedHardwareOutput
 */
export const addNodeIdToResponseObj = (obj) => {
    const _obj = { ...obj };
    if (hasInventoryFields(_obj)) {
        _obj.nodeId = btoa(`["${EntityType.INVENTORY}","${_obj.id}"]`);
    }
    else if (hasItemInstanceFields(_obj)) {
        _obj.nodeId = btoa(`["${EntityType.ITEM_INSTANCE}","${_obj.id}"]`);
    }
    else if (hasItemMasterFields(_obj)) {
        const arr = [
            EntityType.ITEM_MASTER,
            _obj.drawingNumber,
            _obj.asBuiltNumber,
            _obj.side
        ];
        const json = JSON.stringify(arr);
        _obj.nodeId = btoa(json);
    }
    else if (hasItemDrawingFields(_obj)) {
        const arr = [EntityType.ITEM_DRAWING, _obj.drawingNumber];
        const json = JSON.stringify(arr);
        _obj.nodeId = btoa(json);
    }
    return _obj;
};
export const createAxiosInstance = () => {
    const currentUserActiveSupportContext = getActiveSupportContext();
    if (!currentUserActiveSupportContext) {
        console.log('No active support context.');
        return;
    }
    const headers = {
        'Content-Type': 'application/json',
        'x-organization': currentUserActiveSupportContext || ''
    };
    // if you put this value in local storage, it allows you to use the system as that user.
    // this accountId can be created using the API's CLI command, add-user CONTEXT ADMIN|USER.
    const devUserAccountId = window.localStorage.getItem('devUserAccountId');
    const env = getEnvironment();
    // for security, also check for localhost
    // API2 doesnt look at this header it gets the accountId from the JWT sent over by keycloak
    if (devUserAccountId && env === CosmicEnvironment.LOCAL) {
        headers['x-auth-subject'] = devUserAccountId;
    }
    const v2HttpClient = axios.create({
        baseURL: `/api2`,
        headers,
        withCredentials: true,
        paramsSerializer: (params) => qs.stringify(params, {
            allowDots: true,
            arrayFormat: 'repeat'
        }),
        transformRequest: [transformDates]
    });
    // Add our interceptor
    v2HttpClient.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        if (!error || !error.response || !error.response.status) {
            return Promise.reject(error);
        }
        if (error.response.status === 401) {
            window.location.href = AUTH_PATH;
            return;
        }
        if (error.response.status === 403) {
            window.location.href = '/no_access';
            return;
        }
        return Promise.reject(error);
    });
    return v2HttpClient;
};
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $http: { value: createAxiosInstance() }
        });
    }
};

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mini_assembly_template"
  }, [_vm.title ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.transformedSelectedEntity ? _c('div', {
    staticClass: "entity-mini",
    class: {
      hasChildren: _vm.myChildren.length,
      isRoot: _vm.isRoot,
      showingChildren: _vm.shouldShowChildContainer
    },
    on: {
      "dblclick": _vm.onClickOfShowChildren
    }
  }, [_c('div', {
    attrs: {
      "tooltip": "Sequence is how COSMIC keeps the children in order.",
      "tooltip-position": "right"
    }
  }, [_vm.isRoot ? _c('div', {
    staticClass: "c-flex align-items-center justify-content-center"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-color": "#f0a225",
      "icon": _vm.$icons.root
    }
  })], 1) : _c('div', {
    staticClass: "mini_sequence monospace_font text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.sequence) + " ")])]), _vm.transformedSelectedEntity._name ? _c('div', {
    staticClass: "entity-mini-name text-truncate"
  }, [_c('div', {
    staticClass: "mini-name monospace_font text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._name) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "mini_chip_container"
  }, [_vm.transformedSelectedEntity.autoHardwareListId ? _c('div', {
    staticClass: "single_chip auto_hardware_list",
    attrs: {
      "tooltip": "This is associated with the item listing ".concat(_vm.transformedSelectedEntity.autoHardwareListName, "."),
      "tooltip-position": "right"
    },
    on: {
      "dblclick": function dblclick($event) {
        return _vm.uiClickOfViewAutoHardwareListInfo(_vm.transformedSelectedEntity.autoHardwareListId);
      }
    }
  }, [_c('IconAutoHardwareList', {
    attrs: {
      "icon-size": "14px"
    }
  }), _vm.transformedSelectedEntity.autoHardwareListName ? _c('div', {
    staticClass: "monospace_font text-truncate"
  }, [_c('strong', [_vm._v(_vm._s(_vm.transformedSelectedEntity.autoHardwareListName))])]) : _vm._e()], 1) : _vm._e(), _vm._l(_vm.transformedSelectedEntity.parts, function (part) {
    return _c('div', {
      key: part.id,
      staticClass: "single_chip part_reference",
      attrs: {
        "tooltip": "Part selected.",
        "tooltip-position": "right"
      },
      on: {
        "dblclick": function dblclick($event) {
          return _vm.uiClickOfViewPartReferenceInfo(part.partReference);
        }
      }
    }, [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.$icons.part_reference,
        "icon-color": "var(--v-item_instances-base)",
        "icon-size": "14px"
      }
    }), part.partReference ? _c('div', {
      staticClass: "monospace_font text-truncate"
    }, [_c('PartSingleLineDisplay', _vm._b({}, 'PartSingleLineDisplay', _objectSpread({}, part.partReference), false))], 1) : _vm._e()], 1);
  }), _vm.transformedSelectedEntity.documentId ? _c('div', {
    staticClass: "single_chip document text-truncate",
    attrs: {
      "tooltip": "This is associated with a tracking document.",
      "tooltip-position": "right"
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.DOCUMENT],
      "icon-size": "18px",
      "icon-color": "var(--v-documents-base)"
    }
  }), _vm.transformedSelectedEntity.documentNumber ? _c('div', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.transformedSelectedEntity.documentNumber))])]) : _vm._e()], 1) : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "mini_actions_v3"
  }, [_vm.allowInstall ? _c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Install into this slot",
      "icon-size": "18px",
      "icon": _vm.$icons.install,
      "icon-color": "var(--v-success-base)",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfActualizeAssemblyTemplate
    }
  }) : _vm._e(), !_vm.isReadOnly ? _c('ButtonMiniActionCreate', {
    attrs: {
      "tooltip": "Add a child template",
      "icon-size": "18px",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfCreateChildAssemblyTemplate
    }
  }) : _vm._e(), _c('ButtonMiniActionInfo', {
    attrs: {
      "tooltip": "View details of this template",
      "icon-size": "18px",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfViewInfoAssemblyTemplate
    }
  }), !_vm.isReadOnly ? _c('ButtonMiniActionEdit', {
    attrs: {
      "tooltip": "Edit template",
      "icon-size": "18px",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfEditAssemblyTemplate
    }
  }) : _vm._e(), !_vm.isReadOnly ? _c('ButtonMiniActionDelete', {
    attrs: {
      "tooltip": "Delete template",
      "icon-size": "18px",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfDeleteAssemblyTemplate
    }
  }) : _vm._e(), _vm.computedId && _vm.$route.params && _vm.$route.params.id !== _vm.computedId && _vm.isRoot && _vm.showManageOnlyOnRoot ? _c('ButtonMiniActionManage', {
    attrs: {
      "tooltip": "Manage this template",
      "to": "/hardware/assembly_templates/manage/".concat(_vm.computedId, "#information"),
      "icon-size": "18px",
      "tooltip-position": "left"
    }
  }) : _vm._e(), _vm.hasChildren ? _c('ButtonMiniAction', {
    attrs: {
      "disabled": !_vm.hasChildren,
      "icon": _vm.chevronIcon,
      "tooltip": !_vm.hasChildren ? 'No children' : _vm.localShowChildren ? 'Hide children' : 'Show children',
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfShowChildren
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "center": "",
      "type": "linear"
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowChildContainer ? _c('div', {
    staticClass: "mini_child_container"
  }, _vm._l(_vm.myChildren, function (childAssemblyTemplate) {
    return _c('MiniAssemblyTemplate', {
      key: childAssemblyTemplate.id,
      staticClass: "mini_child",
      attrs: {
        "allow-child-creation": _vm.allowChildCreation,
        "allow-edit": _vm.allowEdit,
        "clearable-id": _vm.clearableId,
        "entity": childAssemblyTemplate,
        "inline-clear": _vm.clearableId === childAssemblyTemplate.id,
        "read-only": _vm.isReadOnly,
        "show-children": ""
      },
      on: {
        "refresh-parent": _vm.onCloseOfModalAssemblyTemplateEdit
      }
    });
  }), 1) : _vm._e()]), _vm.showModalAssemblyTemplateEdit ? _c('ModalAssemblyTemplateEdit', {
    key: _vm.computedId,
    attrs: {
      "assembly-template-id": _vm.computedId,
      "parent-assembly-template-id": _vm.parentAssemblyTemplateId
    },
    on: {
      "modal-close": _vm.onCloseOfModalAssemblyTemplateEdit,
      "refresh-parent": _vm.emitRefreshParent,
      "refresh-self": _vm.onCloseOfModalAssemblyTemplateEdit
    }
  }) : _vm._e(), _vm.showModalAssemblyTemplateCreate ? _c('ModalAssemblyTemplateCreate', {
    key: _vm.computedId,
    attrs: {
      "parent-assembly-template-id": _vm.computedId
    },
    on: {
      "modal-close": _vm.onCloseOfModalAssemblyTemplateCreate,
      "refresh-self": _vm.onCloseOfModalAssemblyTemplateCreate
    }
  }) : _vm._e(), _vm.showModalAssemblyTemplateInfo ? _c('ModalAssemblyTemplateInfo', {
    key: _vm.computedId,
    attrs: {
      "assembly-template-id": _vm.computedId
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showModalAssemblyTemplateActualization && _vm.parentInventoryId ? _c('ModalAssemblyTemplateActualization', {
    key: _vm.computedId,
    attrs: {
      "assembly-template-id": _vm.computedId,
      "parent-inventory-id": _vm.parentInventoryId
    },
    on: {
      "actualized": _vm.onTemplateActualization,
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showModalPartReferenceInfo && _vm.selectedPartReference ? _c('ModalPartReferenceInfo', {
    attrs: {
      "id": _vm.selectedPartReference.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showModalAutoHardwareListInfo && _vm.selectedAutoHardwareListId ? _c('ModalAutoHardwareListInfo', {
    attrs: {
      "id": _vm.selectedAutoHardwareListId
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
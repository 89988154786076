import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { getPartReference } from '~/nasa_ui/utils';
let ModalPartReferenceInfo = class ModalPartReferenceInfo extends Mixins(BaseModal) {
    selectedEntity = null;
    id;
    get computedModalWidth() {
        return this.selectedEntity ? (this.selectedEntity.itemInstanceId ? `95vw` : `75vw`) : `75vw`;
    }
    async fetchSelectedEntity() {
        try {
            const resp = await getPartReference(this.id);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalPartReferenceInfo.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalPartReferenceInfo.prototype, "fetchSelectedEntity", null);
ModalPartReferenceInfo = __decorate([
    Component
], ModalPartReferenceInfo);
export default ModalPartReferenceInfo;
